<template>
  <div class="settings-programs">
    <vue-good-table
      :ref="tableReference"
      :is-loading="isLoading"
      :total-rows="totalRecords"
      :columns="columns"
      :rows="programs"
      style-class="vgt-table"
      mode="remote"
      :pagination-options="{
        enabled: true
      }"
      :search-options="{
        enabled: true,
        externalQuery: settingsSearch
      }"
      :max-height="maxHeight"
      :fixed-header="true"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearchTermChange"
      @on-row-click="openEditProgram"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.label == $t('programsTable.program')">
          <p class="name">{{ props.row.full_name }}</p>
          <p
            v-if="props.row.parent_id && false"
            class="program-group-date"
          >
            {{ props.row.name }} / {{ daysOfWeek[props.row.week_date_day].abbr }} {{ props.row.week_date_time }}
          </p>
        </span>
        <span v-else-if="props.column.label == $t('programsTable.length')">
          {{ getLengthText(props.row.length) }}
        </span>
        <span v-else-if="props.column.label == $t('programsTable.clinicians')">
          {{ getCliniciansAndTeams(props.row.program_clinicians, props.row.program_clinician_teams) }}
        </span>
      </template>
      <div
        v-show="isLoading"
        slot="loadingContent"
      >
        <LoadingSpinner />
      </div>
      <div
        v-show="!isLoading"
        slot="emptystate"
      >
        {{ $t('noProgramsFound') }}
      </div>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <PaginationFooter
          v-show="paginationEnabled"
          :total="props.total"
          :server-params="serverParams"
          :per-page-changed="props.perPageChanged"
          :page-changed="onPageChange"
        />
      </template>
    </vue-good-table>
    <overlay
      v-if="routeMatch"
      @close="closeOverlay"
    >
      <template slot="content">
        <router-view />
      </template>
    </overlay>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import DayOfWeeksData from '@/data/days-of-week-data'
import { ClinicianPrograms } from '@/mixins/TableColumnsHelper'
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import { SettingsCrud } from '@/mixins/SettingsCrud'
import PaginationFooter from '@/components/common/PaginationFooter'
import { GET_SETTINGS_PROGRAMS } from '@/store/modules/programs/constants.js'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import Overlay from '@/components/clinician/Overlay'

export default {
  name: 'SettingsPrograms',
  components: {
    Overlay,
    VueGoodTable,
    LoadingSpinner,
    PaginationFooter
  },
  mixins: [Helpers, ClinicianPrograms, RemoteTable, SettingsCrud],
  data () {
    return {
      tableMaxHeight: 154,
      tableReference: 'settingsPrograms',
      action: GET_SETTINGS_PROGRAMS,
      defaultSortOrder: 'asc',
      defaultSortField: 'name',
      daysOfWeek: DayOfWeeksData
    }
  },
  computed: {
    ...mapGetters({
      programs: 'getSettingPrograms'
    }),
    routeMatch () {
      const routeNameAllowed = [
        'SettingsAddProgram',
        'SettingsEditProgram',
        'SettingsProgramAddMeasure'
      ]
      return routeNameAllowed.indexOf(this.$route.name) !== -1
    }
  },
  created () {
    this.loadItems()
  },
  destroyed () {
    this.$store.dispatch('RESET_PROGRAM_JUST_ADDED')
  },
  methods: {
    openAdd () {
      this.$router.push({ name: 'SettingsAddProgram' })
    },
    openEditProgram (params) {
      this.$router.push({ name: 'SettingsEditProgram', params: { programId: params.row.id } })
    },
    closeOverlay () {
      this.$router.push({ name: 'SettingsPrograms' })
    },
    getLengthText (length) {
      if (!length) {
        return 'NONE'
      }

      let suffix = 'DAYS'
      if (length % 7 === 0) {
        suffix = Number(length) === 7 ? 'WK' : 'WKS'
        length = length / 7
      }
      return `${length} ${suffix}`
    },
    getCliniciansAndTeams (clinicians, teams) {
      const cliniciansMapped = clinicians.map(item => {
        return item.user_profile.person_information.full_name
      })

      const teamsMapped = teams.map(item => {
        return item.clinician_team.name
      })
      return cliniciansMapped.concat(teamsMapped).join(', ')
    }
  }
}
</script>
